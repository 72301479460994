import { useEffect, useState } from 'react'
import './ShopProductCarousel.css'
import { FaShoppingCart } from 'react-icons/fa';
import { CloseCircleOutlined } from '@ant-design/icons'
import './Click.css'
import { getBooksByCount, getBooksByCategories, booksByAuthor } from '../../functions/book'
import { toast } from 'react-toastify'
import { addToCart } from '../../functions/cart'
import { useSelector } from 'react-redux';
import { getCartList } from '../../functions/cart';
import { useNavigate } from 'react-router-dom';
import { getCategories } from '../../functions/category';
import { useDispatch } from 'react-redux';
import { getBestSellingBooks } from '../../functions/bestSellers';
import { FadeLoader } from 'react-spinners';


function ShopProductCarousel({ searchKeyword, searchedResponse, selectedAuthorId, selectedAuthorName, cartLength, filterLabel, onSelectedAuthor, ageFilter }) {

  console.log('child', ageFilter)
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const logoUrl = '/bookkulturelogo.png';
  const { user } = useSelector((state) => ({ ...state }));
  const { cart } = useSelector((state) => ({ ...state }));

  const [selectedimg, setSelectedimg] = useState();
  const [openpopup, setOpenpopup] = useState(false);
  const [books, setBooks] = useState([]);
  const [bookPopUp, setBookPopUp] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [filteredBooks, setFilteredBooks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [bestSellersBooks, setBestSellersBooks] = useState([]);
  const [booksByCategories, setbooksByCategories] = useState([]);
  const [searchedBooks, setSearchedBooks] = useState([]);
  const [filterResult, setFilterResult] = useState([]);

  const handleExplore = (genere, genere_id) => {
    navigate('/explore', {
      state: {
        genere,
        genere_id
      },
    });
  }

  useEffect(() => {
    loadAllProducts();
    const userDataExists = localStorage.getItem('cartItems') !== null;
    if (!userDataExists) loadCart();
    loadCategories();
    bestSellingBooks();
  }, [])


  useEffect(() => {
    loadBooksByAuthor(filterLabel, selectedAuthorId, selectedAuthorName)
  }, [selectedAuthorId, selectedAuthorName]);

  const loadBooksByAuthor = (filterLabel, selectedAuthorId, selectedAuthorName) => {
    // console.log("incoming--", filterLabel, selectedAuthorId, selectedAuthorName)
    if (filterLabel == 'Author') {
      const data = { author: selectedAuthorId }
      booksByAuthor(50, data)
        .then((res) => {
          console.log(res);
          setFilterResult(res.data);
        })
        .catch((err) => {
          console.log(err);
        })
    } else if (filterLabel == 'Genre') {
      const data = { category: selectedAuthorId }
      getBooksByCategories(50, data)
        .then((res) => {
          console.log(res);
          setFilterResult(res.data);
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }

  useEffect(() => {
    if (searchedResponse.length > 0) {
      setSearchedBooks(searchedResponse);
    } else {
      setSearchedBooks([]);
    }
  }, [searchedResponse]);

  const loadCart = () => {
    if (user) {
      if (user.role === 'subscriber') {
        getCartList(user.token)
          .then((res) => {
            console.log(res.data)
            // setCartContainer(res.data.length);
            // localStorage.setItem('cartItems', JSON.stringify(res.data));
            // cartLength(res.data.length);
            // setCartItems(savedCartItems);
          })
          .catch((err) => {
            // console.log(err);
            if (err.response.data.err === "Subscription expired. Access denied.") {
              // toast.error("Subscription expired, Please renew");
            }
            // toast.error('An Error Occured');
          })
      }
    }
  }

  const loadCategories = () => {
    getCategories()
      .then((res) => {
        setCategories(res.data);
        booksByCategoriesMethod(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error Occured")
      })
  }

  const filterBooks = () => {
    const filtered = books.filter((book) => {
      const authorFilter =
        (!selectedAuthorId || (book.author._id === selectedAuthorId) || (book.category._id === selectedAuthorId)) &&
        (!selectedAuthorName || (book.author.name === selectedAuthorName) || (book.category.name === selectedAuthorName));

      const keywordFilter = book.title.toLowerCase().includes(searchKeyword.toLowerCase());
      return authorFilter && keywordFilter;
    });
    setFilteredBooks(filtered);
  };

  useEffect(() => {
    filterBooks();
  }, [books, selectedAuthorId, selectedAuthorName, searchKeyword]);

  const selectmefunction = (bookImg) => {
    setSelectedimg(bookImg);
  }

  const selectmefunction2 = (bookImg) => {
    setSelectedimg(bookImg);
  }

  const hidepopfunction = () => {
    setSelectedimg();
    setOpenpopup(false);
  }

  const openpopupfunction = (book) => {
    setOpenpopup(true);
    setBookPopUp(book);
  }

  const bestSellingBooks = () => {
    getBestSellingBooks(10)
      .then((res) => {
        setBestSellersBooks(res.data);
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const booksByCategoriesMethod = (category) => {
    for (let i = 0; i < category.length; i++) {
      const data = {
        category: category[i]._id,
      }
      getBooksByCategories(100, data)
        .then((res) => {
          let data = [];
          data = res.data;
          data.sort((a, b) => b.quantity - a.quantity)
          setbooksByCategories(prevState => [...prevState, ...res.data]);
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }


  const loadAllProducts = () => {
    // setLoading(true);
    // getBooksByCount(1800)
    //   .then((res) => {
    //     setBooks(res.data);
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     console.log(err);
    //   })
  }



  const handleAddToCart = (book) => {
    if (book.quantity > 0) {
      if (cartItems.includes(book._id)) {
        toast.error('Already in the cart');
      } else {
        if (user && user.role === 'subscriber') {
          addToCart(book, user.token)
            .then((res) => {
              if (res.data === "Already in the cart") {
                toast.error(res.data);
              } else {
                toast.success('Added to the Cart');
                localStorage.setItem('cartItems', JSON.stringify(res.data.cart.length));
                localStorage.setItem('cartUpdated', true);
                cartLength(res.data.cart.length);
              }
            })
            .catch((err) => {
              if (err?.response?.data === "Already in the cart" || err?.response?.data === "Cannot add more than 15 books in the cart") {
                toast.error(err?.response?.data);
              }else toast.error("Error Occured")
              console.log(err);
            });
        } else {
          toast.error("Please subscribe")
        }
      }
    } else {
      toast.error("Book Unavailable");
    }


  };




  return <>
    {openpopup && (

      <div className="mainbgdiv">
        <CloseCircleOutlined
          className="cancel"
          style={{ fontSize: '60px', color: 'red' }}
          onClick={hidepopfunction}
        />

        <div className='mainbgdiv_content'>
          <div className='mainbgdiv_productdetails'>
            <div className='mainbgdiv_productimg'>
              <img className="mainimg" src={selectedimg || (bookPopUp.images[0] ? (bookPopUp.images[0].public_id.length <= 13  ? 'https://book-images.blr1.digitaloceanspaces.com/' + bookPopUp.images[0].public_id + '.jpg' : bookPopUp.images[0].url)  : logoUrl)} alt="Product"></img>
              {bookPopUp.images.length > 1 && (
                <div className='imglist'>
                  <img src={bookPopUp.images[0] ? (bookPopUp.images[0].public_id.length <= 13  ? 'https://book-images.blr1.digitaloceanspaces.com/' + bookPopUp.images[0].public_id + '.jpg' : bookPopUp.images[0].url) : logoUrl} 
                    onClick={() => { selectmefunction(bookPopUp.images[0] ? bookPopUp.images[0].url : logoUrl) }} alt="Product">
                  </img>
                  <img src={bookPopUp.images[1] ? bookPopUp.images[1].url : logoUrl} onClick={() => { selectmefunction2(bookPopUp.images[1] ? bookPopUp.images[1].url : logoUrl) }} alt="Product"></img>
                </div>
              )}

            </div>
            <div className="mainbgdiv_productdes">
              <h1>{bookPopUp.title}</h1>
              <p>
                {bookPopUp.description}
              </p>
              {bookPopUp.quantity > 0 ? (
                <h2 style={{ color: "green" }}>Available: {bookPopUp.quantity}</h2>
              ) : (
                <h2 style={{ color: "red" }}>Unavailable: {bookPopUp.quantity}</h2>
              )}

              {bookPopUp?.category?.name && bookPopUp.age && (
                <>
                <h2>Category: {bookPopUp?.category?.name}</h2>
                <h2>Age: {bookPopUp?.age}</h2>
                </>
              )}
              

              <div className="addtocart">
                <button disabled={bookPopUp.quantity === 0} onClick={() => handleAddToCart(bookPopUp)}>
                  <FaShoppingCart className="icon" />
                  Add to Cart
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}

    { }

    {/* Displaying Filter results */}

    {filterLabel && selectedAuthorId && selectedAuthorName && (
      <>
        <div className='ShopProductCarousel_container_bestSeller'>
          <div className='shop_explore'>
            <h3 className='genre' >Filter Result</h3>
          </div>
          <div className="wrapper">
            {filterResult.length > 0 ? filterResult.map((book) => (
              <div key={book._id}>
                <div className="ProductCard">
                  <div className="ProductImg">
                    {book.images[0] ? (
                      <img
                        src={book.images[0] ? (book.images[0].public_id.length <= 13  ? 'https://book-images.blr1.digitaloceanspaces.com/' + book.images[0].public_id + '.jpg' : book.images[0].url) : logoUrl}
                        //src={book.images[0].url}
                        onClick={() => openpopupfunction(book)}
                        alt="Product"
                      />
                    ) : (
                      <img
                        src={logoUrl}
                        onClick={() => openpopupfunction(book)}
                        alt="Product"
                      />
                    )}
                  </div>
                  <div className="Product-cardheader">
                    <div className="Product-cardheader_content">
                      <p>{book.title}</p>

                      {book.quantity > 0 ? (
                        <p className="available">Available</p>
                      ) : (
                        <p className="available" style={{ color: "red" }}>Unavailable</p>
                      )}
                    </div>
                  </div>
                  <div className="addtocart">
                    <button onClick={() => handleAddToCart(book)}>
                      <FaShoppingCart className="icon" />
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            )) : (
              <>
                <p>No result found</p>
              </>
            )
            }
          </div>
        </div>
      </>
    )}

    {/* Displaying search results */}

    {searchKeyword.length > 0 && (
      <div className='ShopProductCarousel_container_bestSeller'>
        <div className='shop_explore'>
          <h3 className='genre' >Search Result</h3>
        </div>
        <div className="wrapper">
          {searchedBooks.length > 0 ? (
            <>
              {searchedBooks.map((book) => (
                <div key={book._id}>
                  <div className="ProductCard">
                    <div className="ProductImg">
                      {book.images[0] ? (
                        <img
                          src={book.images[0] ? (book.images[0].public_id.length <= 13  ? 'https://book-images.blr1.digitaloceanspaces.com/' + book.images[0].public_id + '.jpg' : book.images[0].url) : logoUrl}
                          //src={book.images[0].url}
                          onClick={() => openpopupfunction(book)}
                          alt="Product"
                        />
                      ) : (
                        <img
                          src={logoUrl}
                          onClick={() => openpopupfunction(book)}
                          alt="Product"
                        />
                      )}
                    </div>
                    <div className="Product-cardheader">
                      <div className="Product-cardheader_content">
                        <p>{book.title}</p>

                        {book.quantity > 0 ? (
                          <p className="available">Available</p>
                        ) : (
                          <p className="available" style={{ color: "red" }}>Unavailable</p>
                        )}
                      </div>
                    </div>
                    <div className="addtocart">
                      <button onClick={() => handleAddToCart(book)}>
                        <FaShoppingCart className="icon" />
                        Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (<p>No result found</p>)}
        </div>
      </div>
    )}

    {/* displaying best seller books */}

    {bestSellersBooks.length > 0 && (
      <>
        <div className='ShopProductCarousel_container_bestSeller'>
          <div className='shop_explore'>
            <h3 className='genre' >Best Seller's</h3>
          </div>
          <div className="wrapper">
            {bestSellersBooks.map((book) => (
              <div key={book._id}>
                <div className="ProductCard">
                  <div className="ProductImg">
                    {book.books.images[0] ? (
                      <img
                        src={book.books.images[0] ? (book.books.images[0].public_id.length <= 13  ? 'https://book-images.blr1.digitaloceanspaces.com/' + book.books.images[0].public_id + '.jpg' : book.books.images[0].url) : logoUrl}
                        onClick={() => openpopupfunction(book.books)}
                        alt="Product"
                      />
                    ) : (
                      <img
                        src={logoUrl}
                        onClick={() => openpopupfunction(book)}
                        alt="Product"
                      />
                    )}
                  </div>
                  <div className="Product-cardheader">
                    <div className="Product-cardheader_content">
                      <p>{book.books.title}</p>

                      {book.books.quantity > 0 ? (
                        <p className="available">Available</p>
                      ) : (
                        <p className="available" style={{ color: "red" }}>Unavailable</p>
                      )}
                    </div>
                  </div>
                  <div className="addtocart">
                    <button disabled={book.books.quantity === 0} onClick={() => handleAddToCart(book.books)}>
                      <FaShoppingCart className="icon" />
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    )}


    {/* Displaying all books by categories */}

    {loading ? (
      <div className="loader" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <FadeLoader color={'red'} loading={loading} size={150} />
      </div>
    ) : (
      <div className='ShopProductCarousel_container'>
        {categories.map((c) => {
          // Filter books by category and age
          const filteredBooks = booksByCategories
            .filter((book) => book.category.name === c.name)
            .filter((book) => !ageFilter || book.age.toLowerCase() === ageFilter.toLowerCase());

          // Render the category only if there are books after filtering
          if (filteredBooks.length === 0) return null;

          return (
            <div key={c._id}>
              <div className='shop_explore'>
                <h3 onClick={() => handleExplore(c.name, c._id)} className='genre'>{c.name}</h3>
              </div>
              <div className="wrapper">
                {filteredBooks.map((book) => (
                  <div key={book._id}>
                    <div className="ProductCard">
                      <div className="ProductImg">
                        <img
                          src={book.images[0] ? (book.images[0].public_id.length <= 13  ? 'https://book-images.blr1.digitaloceanspaces.com/' + book.images[0].public_id + '.jpg' : book.images[0].url) : logoUrl}
                          onClick={() => { openpopupfunction(book) }}
                          alt="Product"
                        />
                      </div>
                      <div className="Product-cardheader">
                        <div className="Product-cardheader_content1">
                          <p>{book.title.slice(0, 18)}</p>
                          <p className='slice_title'>{book.title.slice(18)}</p>
                          {book.quantity > 0 ? (
                            <p className="available">Available</p>
                          ) : (
                            <p className="available" style={{ color: "red" }}>Unavailable</p>
                          )}
                        </div>
                      </div>
                      <div className="addtocart">
                        <button disabled={book.quantity === 0} onClick={() => handleAddToCart(book)}>
                          <FaShoppingCart className="icon" />
                          Add to Cart
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    )}



    {/* {loading ? (
      <>
        <div className="loader" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <FadeLoader color={'red'} loading={loading} size={150} />
        </div>
      </>
    ) : (
      <div className='ShopProductCarousel_container'>
        {categories.map((c) => (
          <div key={c._id} >
            <div className='shop_explore'>
              {(
                <h3 onClick={() => handleExplore(c.name, c._id)} className='genre' >{c.name}</h3>
              )}
            </div>
            <div className="wrapper">
              {booksByCategories
                .filter((book) => book.category.name === c.name)
                .filter((book) => !ageFilter || book.age.toLowerCase() === ageFilter.toLowerCase())
                .map((book) => (
                <div key={book._id}>
                  <div className="ProductCard">
                    <div className="ProductImg">
                      <img src={book.images[0] ? book.images[0].url : logoUrl} onClick={() => { openpopupfunction(book) }} alt="Product" />
                    </div>
                    <div className="Product-cardheader">
                      <div className="Product-cardheader_content1">
                        <p>{book.title.slice(0, 18)}</p>
                        <p className='slice_title'>{book.title.slice(18)}</p>
                        {book.quantity > 0 ? (
                          <p className="available">Available</p>
                        ) : (
                          <p className="available" style={{ color: "red" }}>Unavailable</p>
                        )}
                      </div>
                    </div>
                    <div className="addtocart">
                      <button disabled={book.quantity === 0} onClick={() => handleAddToCart(book)}>
                        <FaShoppingCart className="icon" />
                        Add to Cart
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    )} */}

  </>

}
export default ShopProductCarousel;