// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.head-order-history{
    color: red;
    display: flex;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 15px;
}

.ProductCard {
    width: 110px; /* Adjust this to scale down */
    margin-right: 14px;
    margin-left: 14px;
    margin-bottom: 16px;
  }

.ProductImg img {
width: 100%;
/* height: auto; */
}`, "",{"version":3,"sources":["webpack://./src/pages/User/OrderHistory/orderHistory.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,YAAY,EAAE,8BAA8B;IAC5C,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;EACrB;;AAEF;AACA,WAAW;AACX,kBAAkB;AAClB","sourcesContent":[".head-order-history{\n    color: red;\n    display: flex;\n    justify-content: center;\n    margin-top: 25px;\n    margin-bottom: 15px;\n}\n\n.ProductCard {\n    width: 110px; /* Adjust this to scale down */\n    margin-right: 14px;\n    margin-left: 14px;\n    margin-bottom: 16px;\n  }\n\n.ProductImg img {\nwidth: 100%;\n/* height: auto; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
